import React from 'react';
// @ts-ignore
import {MobileBreakpoint,GeneralAssetUrl} from './../../themes/kent/utilities/constants';
import Toggle from './../toggle/./toggle';

let accessibilityOptions = [
  {
    id:'largeText',
    title:'Large Text',
    icon:GeneralAssetUrl+'accessibility-large-text.png',
    value:false,
  },
  {
    id:'darkMode',
    title:'Dark Mode',
    icon:GeneralAssetUrl+'accessibility-dark-mode.png',
    value:false,
  },
  {
    id:'highContrastImages',
    title:'High Contrast Images',
    icon:GeneralAssetUrl+'accessibility-high-contrast-images.png',
    value:false,
  },
  {
    id:'minimizeOverlays',
    title:'Darken Background With Text',
    icon:GeneralAssetUrl+'accessibility-minimize-overlays.png',
    value:false,
  },
  {
    id:'invertColors',
    title:'Invert Colors',
    icon:GeneralAssetUrl+'accessibility-invert-colors.png',
    value:false,
  },
  {
    id:'greyScale',
    title:'Convert Site to Grey Scale',
    icon:GeneralAssetUrl+'accessibility-grey-scale.png',
    value:false,
  },
  {
    id:'removeVideos',
    title:'Remove All Videos From Site',
    icon:GeneralAssetUrl+'accessibility-remove-videos.png',
    value:false,
  },
  {
    id:'highlightLinks',
    title:'Highlight All Links',
    icon:GeneralAssetUrl+'accessibility-highlight-links.png',
    value:false,
  }
]

interface IState {
  accessibilityDetail?:boolean;
  accessibilityOptions?:any;
  updateKey:number;
}

class AccessibilityWidget extends React.Component{

  public state: IState;

  constructor (props: any) {
      super(props);
      this.state = {
        updateKey:1212
      };
  }

  componentDidMount = () =>{
    let currentOptions = [];
    var element = document.getElementById("main");
    if(element){
      let classList = element.classList;
      classList.add('primary-background')
    }
    for (let i = 0; i < accessibilityOptions.length; i++) {
      let value = false;
      let localValue = localStorage.getItem(accessibilityOptions[i].id);
      if(localValue){
        if(localValue == "true"){
          value = true
        }
      }
      currentOptions.push({
        id:accessibilityOptions[i].id,
        title:accessibilityOptions[i].title,
        value:value
      })
    }
    this.setState({
      accessibilityOptions:currentOptions
    },()=>{
      this.updateDom()
    })
  }
  toggleAccessibility = (data:any) =>{
    console.log(data)
    let accessibilityOptions = this.state.accessibilityOptions;
    for (let i = 0; i < accessibilityOptions.length; i++) {
      if(accessibilityOptions[i].id == data.id){
        let value = accessibilityOptions[i].value ? false : true;
        localStorage.setItem(accessibilityOptions[i].id,value ? 'true':'false');
        accessibilityOptions[i].value = value
      }
    }
    console.log(accessibilityOptions)
    this.setState({
      accessibilityOptions: accessibilityOptions,
      updateKey: this.state.updateKey+1
    },()=>{
      this.updateDom()
    })
  }
  toggleAccessibilityDetail = () =>{
    this.setState({
      accessibilityDetail: this.state.accessibilityDetail ? false : true
    })
  }
  updateDom = () =>{
    for (let i = 0; i < this.state.accessibilityOptions.length; i++) {
      if(accessibilityOptions[i].id){
        let localValue = localStorage.getItem(accessibilityOptions[i].id)
        var element = document.getElementsByTagName("html");
        if(element){
          if(element[0]){
            let classList = element[0].classList;
            if(localValue){
              if(localValue == "true"){
                classList.add(accessibilityOptions[i].id)
              } else{
                classList.remove(accessibilityOptions[i].id)
              }
            }
          }
        }
      }
    }
  }

  public render(): JSX.Element {
    let accessibilityModeClass = (this.state.accessibilityOptions && this.state.accessibilityOptions.filter((option:any) => option.value == true)) ?  "accessibility-mode-enabled" : "";
    let accessibilityDetailClass = this.state.accessibilityDetail ? 'active' : '';
    return <>
      <div className={"accessibility-mode-toggle primary-color-background "+accessibilityModeClass} onClick={this.toggleAccessibilityDetail}>
        <img className="full-width" src={GeneralAssetUrl+'accessibility.png'} />
      </div>
      <div className={"accessibility-mode-detail primary-background global-radius shadow-light freeze-text-size "+accessibilityDetailClass}>
        <div className="accessibility-mode-header primary-color-background">
          <h5 className="white">{'Accessibility Options'}</h5>
          <div className="accessibility-mode-close-icon" onClick={this.toggleAccessibilityDetail}>
            <img className="full-width" src={GeneralAssetUrl+'close-white.png'} />
          </div>
        </div>
        <div className="accessibility-mode-content">
          {this.state.accessibilityOptions && this.state.accessibilityOptions.map((data:any, index:number) =>{
            return(
              <div className="accessibility-mode-option">
                <div className="accessibility-mode-option-icon">
                  <img className="full-width" src={data.icon} />
                </div>
                <div className="accessibility-mode-option-text text-color-primary">
                  {data.title}
                </div>
                <div className="accessibility-mode-option-toggle" onClick={()=>this.toggleAccessibility(data)}>
                  <Toggle key={this.state.updateKey+index} active={data.value} />
                </div>
              </div>
            )
          })}
        </div>
        <div style={{padding:"10px"}}>
          <p className="text-color-primary"><a href="accessibility">{'Learn more'}</a> {'about our commitment to accessibility'}</p>
        </div>
      </div>
    </>
  }
}

 export default AccessibilityWidget;
