import React from 'react';

interface IProps {
  active?: boolean;
}

class Toggle extends React.Component<IProps>{
  public render(): JSX.Element {
    let activeClass="";
    let colorClass="grey-background";
    if(this.props.active){
      activeClass = 'active'
      colorClass = "primary-color-background"
    }
    return <>
      <div className="toggle-wrapper">
        <div className={"toggle-container secondary-background "+activeClass}>
          <div className={"toggle-button "+colorClass}></div>
        </div>
      </div>
    </>
  }
}

 export default Toggle;
