import React from 'react';
// @ts-ignore
import {MobileBreakpoint,GeneralAssetUrl} from './../../themes/kent/utilities/constants';
// @ts-ignore
import Zendesk from "react-zendesk";

interface IProps {
  zendeskKey?: any;
}
class ZendeskWidget extends React.Component<IProps>{
  constructor(props: IProps) {
    super(props);

  }
  public render(): JSX.Element {
    if(!this.props.zendeskKey){
       return <span />
    } else{
      return <Zendesk defer zendeskKey={this.props.zendeskKey} onLoaded={() => console.log('zendesk is loaded')} />
    }
  }
}

 export default ZendeskWidget;
